import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  useToast,
} from 'me-component-library';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUpdateEmailAddress } from '../../../../hooks/useUpdateEmailAddress';
import { useTranslation } from '../../../../../_shared/hooks';

interface EmailEditTabGeneraChangeAddressFormProps {
  currentEmail: string;
  emailId: string;
}

export const EmailEditTabGeneraChangeAddressForm: React.FC<
  EmailEditTabGeneraChangeAddressFormProps
> = ({ currentEmail, emailId }) => {
  const { mutate, isLoading } = useUpdateEmailAddress();
  const { toast } = useToast();
  const t = useTranslation();

  const toggleSuccesToast = () => {
    toast({
      title: t(
        'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.toasts.success.title',
      ),
      description: t(
        'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.toasts.success.message',
      ),
      variant: 'success',
      duration: 5000,
    });
  };

  const toggleErrorToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.shared.toasts.error.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.error.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const formSchema = z.object({
    email: z
      .string()
      .email({
        message: t(
          'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.errors.invalidEmail',
        ),
      })
      .refine(
        value => {
          const currentDomain = currentEmail.split('@')[1];
          const newDomain = value.split('@')[1];
          return currentDomain === newDomain;
        },
        {
          message: t(
            'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.errors.wrongDomain',
          ),
        },
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: currentEmail,
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    mutate(
      {
        emailId,
        address: values.email,
      },
      {
        onSuccess: () => {
          toggleSuccesToast();
        },
        onError: () => {
          toggleErrorToast();
        },
      },
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Label>
          {t(
            'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.title',
          )}
        </Label>
        <div className="flex gap-4">
          <FormField
            control={form.control}
            name="email"
            key="email"
            render={({ field }) => (
              <FormItem className="flex grow flex-col">
                <FormControl>
                  <Input
                    placeholder={t(
                      'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.title',
                    )}
                    data-testid="email-input"
                    className="w-full"
                    type="text"
                    {...field}
                    onBlur={async () => form.trigger('email')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="select-none"
            type="submit"
            isLoading={isLoading}
            disabled={
              !form.formState.isValid || isLoading || !form.formState.isDirty
            }
            data-testid="submit-button"
          >
            {t(
              'dashboard.emailEditTabs.tabs.general.common.changeAddressForm.button',
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
