import { api } from './api';
import { LoginResponse, LoginCredentials, UserData } from '../types';

export async function getUserToken({
  username,
  password,
}: LoginCredentials): Promise<LoginResponse> {
  const response = await api.post(`public/login`, {
    json: {
      username,
      password,
    },
  });
  return response.json();
}

export async function getProfile(): Promise<UserData> {
  const response = await api.get(`me`);
  return response.json();
}
