import { useMutation, useQueryClient } from 'react-query';
import { UpdateOTPValues, updateOTP } from '../../api';

export function useUpdateOTP() {
  const queryClient = useQueryClient();
  return useMutation((variables: UpdateOTPValues) => updateOTP(variables), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: err => {
      console.error('Error updating otp ', err);
    },
  });
}
