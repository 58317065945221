import './CheckBox.scss';

type CheckBoxProps = {
  checked: boolean;
  onChange: (value: boolean) => void;
  inputLabel: string;
};

export const CheckBox = ({ checked, onChange, inputLabel }: CheckBoxProps) => {
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    onChange(isChecked);
  };

  return (
    <>
      <label className="mw-check-box">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheckBoxChange}
        />
        <span className="ml-1">{inputLabel}</span>
      </label>
    </>
  );
};
