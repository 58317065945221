import { api } from './api';

export type UpdateOTPValues = {
  otpToken: string;
  operation: 'enable' | 'disable';
};

export const updateOTP = async ({ operation, otpToken }: UpdateOTPValues) => {
  const response = await api.patch(`me/2fa/${operation}`, {
    json: {
      otpToken,
    },
  });

  return response.ok;
};
