import { CheckCircle } from 'lucide-react';
import { EmailDetail } from '../../../../types';
import { useTranslation } from '../../../../../_shared/hooks';

export type EmailEditTabAutoResponderBadgeProps = {
  startsAt?: EmailDetail['autoResponder']['startsAt'];
  expiresAt?: EmailDetail['autoResponder']['expiresAt'];
  timeFrameChecked: boolean;
};

export const EmailEditTabAutoResponderBadge: React.FC<
  EmailEditTabAutoResponderBadgeProps
> = ({ expiresAt, startsAt, timeFrameChecked }) => {
  const t = useTranslation();

  const formattedStartDate =
    startsAt !== undefined &&
    new Date(startsAt).toLocaleDateString('en-GB').replace(/\//g, '.');
  const formattedExpiryDate =
    expiresAt !== undefined &&
    new Date(expiresAt).toLocaleDateString('en-GB').replace(/\//g, '.');

  if (timeFrameChecked === true && expiresAt === undefined) {
    return null;
  }

  const startIsInFuture = startsAt ? new Date(startsAt) > new Date() : false;

  return (
    <div className="mt-3 flex rounded-full bg-green-500 text-white p-1 text-sm items-center w-96">
      <span className="ml-2">
        <CheckCircle />
      </span>
      {expiresAt === undefined ? (
        <span className="ml-2">
          {t('dashboard.emailEditTabs.tabs.autoresponder.badge.variant1')}
        </span>
      ) : (
        <span className="ml-2">
          {t(
            startIsInFuture
              ? 'dashboard.emailEditTabs.tabs.autoresponder.badge.variant3'
              : 'dashboard.emailEditTabs.tabs.autoresponder.badge.variant2',
          )}{' '}
          {startIsInFuture ? formattedStartDate : formattedExpiryDate}
        </span>
      )}
    </div>
  );
};
