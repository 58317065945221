import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  FormLabel,
} from 'me-component-library';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useTranslation } from '../../../../../_shared/hooks';

interface EmailEditTabStorageProps {
  quotaLimitInGB: string;
  raiseSubmit: (quota: string) => Promise<void>;
  isLoading: boolean;
  showSubmitButton: boolean;
}

export const EmailEditTabStorageQuotaForm: React.FC<
  EmailEditTabStorageProps
> = ({ quotaLimitInGB, raiseSubmit, isLoading, showSubmitButton }) => {
  const t = useTranslation();

  const formSchema = z.object({
    quota: z.string().refine(value => /^\d+(\.\d+)?$/.test(value), {
      message: t(
        'dashboard.emailEditTabs.tabs.storage.sections.quota.form.errors.invalidQuota',
      ),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      quota: quotaLimitInGB.replace(' GB', ''),
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    raiseSubmit(values.quota);
  };

  return (
    <div className="w-64">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="quota"
            key="quota"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t(
                    'dashboard.emailEditTabs.tabs.storage.sections.quota.form.label',
                  )}
                </FormLabel>
                <FormControl>
                  <div className="flex items-center relative">
                    <Input
                      placeholder={t(
                        'dashboard.emailEditTabs.tabs.storage.sections.quota.form.label',
                      )}
                      data-testid="quota-input"
                      type="text"
                      {...field}
                      onBlur={
                        showSubmitButton === false
                          ? form.handleSubmit(handleSubmit)
                          : () => {
                              return;
                            }
                      }
                    />
                    <span className="absolute right-0 mr-4 text-sm">GB</span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {showSubmitButton && (
            <Button
              type="submit"
              className="w-full"
              isLoading={isLoading}
              disabled={!form.formState.isValid || isLoading}
              data-testid="submit-button"
            >
              {t(
                'dashboard.emailEditTabs.tabs.storage.sections.quota.form.button',
              )}
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};
