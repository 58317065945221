import { UpdateAutoResponderValues } from '../../../../api/updateAutoResponder';
import { EmailDetail } from '../../../../types';
import { TextArea } from 'me-component-library';
import { useTranslation } from '../../../../../_shared/hooks';

export type EmailEditTabAutoResponderMessageFormProps = {
  message: EmailDetail['autoResponder']['message'];
  raiseMutation: (newData: Partial<UpdateAutoResponderValues>) => void;
};

export const EmailEditTabAutoResponderMessageForm: React.FC<
  EmailEditTabAutoResponderMessageFormProps
> = ({ message, raiseMutation }) => {
  const t = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    raiseMutation({ message: e.target.value });
  };

  return (
    <>
      <div className="text-xl mb-3">
        {t('dashboard.emailEditTabs.tabs.autoresponder.form.title')}
      </div>
      <TextArea value={message} onChange={e => handleChange(e)} />
    </>
  );
};
