import { UpdateSpamProtectionValues } from '../../../../api';
import { EmailDetail } from '../../../../types';
import { EmailEditTabSpamButton } from './EmailEditTabSpamButton';
import { useTranslation } from '../../../../../_shared/hooks';

const availableSpamSortingOptions: ['inbox' | 'spam'][] = [['inbox'], ['spam']];

type EmailEditTabSpamSortingProps = {
  data: EmailDetail;
  raiseMutation: (newData: Partial<UpdateSpamProtectionValues>) => void;
};

export const EmailEditTabSpamSorting = ({
  data,
  raiseMutation,
}: EmailEditTabSpamSortingProps) => {
  const t = useTranslation();
  const renderButton = (value: 'inbox' | 'spam') => {
    const active = value === data.mailbox.spamProtection.folder;
    return (
      <EmailEditTabSpamButton key={value} onClick={() => handleClick(value)}>
        <span className={active ? 'active' : ''}>
          {t(
            `dashboard.emailEditTabs.tabs.spam.sections.spamsorting.actions.${value}`,
          )}
        </span>
      </EmailEditTabSpamButton>
    );
  };

  const handleClick = (value: string) => {
    raiseMutation({
      folder: value,
    });
  };

  return (
    <>
      <div className="text-xl">
        {t('dashboard.emailEditTabs.tabs.spam.sections.spamsorting.title')}
      </div>
      <div className="w-5/6 mt-2 text-sm">
        {t(
          'dashboard.emailEditTabs.tabs.spam.sections.spamsorting.description',
        )}
      </div>
      <div className="mt-3 email-edit-tab-spam-grid email-edit-tab-spam-grid__sorting">
        {availableSpamSortingOptions.map(([value]) => renderButton(value))}
      </div>
    </>
  );
};
