import { useTranslation } from '../../../_shared/hooks';
import { AvailableTabs } from '../../types';
import './EmailEditTabBar.scss';

interface EmailEditTabBarProps {
  activeTab: AvailableTabs;
  onTabClick: (tab: AvailableTabs) => void;
  hasMailbox: boolean;
  isAdmin: boolean;
}

interface TabProps {
  isActive: boolean;
  onClick: () => void;
  tabTitle: string;
}

const Tab: React.FC<TabProps> = ({ isActive, onClick, tabTitle }) => {
  return (
    <div
      onClick={onClick}
      className={
        isActive
          ? 'email-edit-tab-bar__tab email-edit-tab-bar__tab--active'
          : 'email-edit-tab-bar__tab'
      }
    >
      <div className="email-edit-tab-bar__link">{tabTitle}</div>
    </div>
  );
};

export const EmailEditTabBar: React.FC<EmailEditTabBarProps> = ({
  activeTab,
  onTabClick,
  hasMailbox,
  isAdmin,
}) => {
  const t = useTranslation();

  const renderTab = (tab: AvailableTabs) => {
    const isActive = activeTab === tab;
    const tabTitle = t(`dashboard.emailDetails.tabs.${tab}`);

    return (
      <Tab
        key={tab}
        isActive={isActive}
        onClick={() => onTabClick(tab)}
        tabTitle={tabTitle}
      />
    );
  };

  return (
    <div className="flex flex-row justify-between items-center w-full email-edit-tab-bar__wrapper">
      <div className="flex flex-row items-center">
        {renderTab(AvailableTabs.GENERAL)}
        {isAdmin && hasMailbox && renderTab(AvailableTabs.STORAGE)}
        {hasMailbox && renderTab(AvailableTabs.SPAM)}
        {renderTab(AvailableTabs.FORWARDS)}
        {hasMailbox && renderTab(AvailableTabs.AUTORESPONDER)}
      </div>
    </div>
  );
};
