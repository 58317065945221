import { useNavigate, useParams } from 'react-router-dom';
import { EmailEditDetails } from '../../components/EmailEditDetails';
import { useGetEmails } from '../../hooks';
import { Icon, Skeleton } from 'me-component-library';
import { PATHS } from '../../../_shared/types';
import { useTranslation } from '../../../_shared/hooks';

export const EmailDetail: React.FC = () => {
  const params = useParams();
  const { isLoading, isError, data } = useGetEmails({ fromCache: true });
  const navigate = useNavigate();
  const t = useTranslation();

  const email = data?.find(email => email.id === params.emailId);

  if (isLoading) {
    return <Skeleton className="w-full h-12" />;
  }

  if (isError) {
    return <div>{t('dashboard.emailDetails.details.errors.generic')}</div>;
  }

  if (email !== undefined && !isLoading) {
    //render email details
    return (
      <div className="w-full">
        <div className="flex items-center">
          <Icon
            name="chevronLeft"
            className="cursor-pointer mr-6"
            onClick={() => navigate('/' + PATHS.DASHBOARD + '/' + PATHS.EMAILS)}
          />
          <h1 className="text-3xl">{email?.email}</h1>
        </div>
        <div className="mt-8">
          <EmailEditDetails emailId={email.id} />
        </div>
      </div>
    );
  } else {
    return <div>{t('dashboard.emailDetails.details.errors.notFound')}</div>;
  }
};
