import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Button,
  Card,
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'me-component-library';
import { useGetToken } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../_shared/types';
import { useTranslation } from '../../../_shared/hooks';
import { writeSessionCookie } from '../../../_shared/utils';

export function LoginForm() {
  const t = useTranslation();
  const navigate = useNavigate();
  const { mutate, isLoading, isError } = useGetToken();

  const formSchema = z.object({
    email: z.string().min(2, {
      message: t('auth.login.errors.emailTooShort'),
    }),
    password: z.string().min(7, {
      message: t('auth.login.errors.passwordTooShort'),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutate(
      {
        username: values.email,
        password: values.password,
      },
      {
        onSuccess: data => {
          if (data.otpRequired) {
            writeSessionCookie('otpAuthToken', data.otpAuthToken);
            navigate(`/${PATHS.TWO_FACTOR}`);
            return;
          }

          // otp not required
          localStorage.setItem('accessToken', data.accessToken);
          navigate(`/${PATHS.DASHBOARD}`);
        },
      },
    );
  }

  return (
    <div className="flex justify-center items-center">
      <Card className="w-96 shadow-xl py-12 bg-white px-8 rounded-md">
        <h1 className="text-2xl mb-1">{t('auth.login.title')}</h1>
        <p className="text-s mb-8">{t('auth.login.subtitle')}</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              key="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('auth.login.emailAddressPlaceholder')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('auth.login.emailAddressPlaceholder')}
                      data-testid="username-input"
                      type="text"
                      autoComplete="username"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              key="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('auth.login.passwordLabel')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('auth.login.passwordPlaceholder')}
                      data-testid="password-input"
                      autoComplete="current-password"
                      type="password"
                      showEyeIcon={true}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              isLoading={isLoading}
              disabled={!form.formState.isValid || isLoading}
              data-testid="submit-button"
            >
              {t('auth.login.loginButton')}
            </Button>
          </form>
        </Form>
        <div className="mt-5">
          <a
            onClick={() => navigate(`/${PATHS.FORGOT_PASSWORD}`)}
            className="text-s text-indigo-500 cursor-pointer"
          >
            {t('auth.login.forgotPassword')}
          </a>
        </div>
        {isError && (
          <p
            className="text-s text-red-600 text-center mt-4"
            data-testid="error-message"
          >
            {t('auth.login.errorMessage')}
          </p>
        )}
      </Card>
    </div>
  );
}
