export const writeSessionCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}; path=/; secure; samesite=strict`;
};

export const readSessionCookie = (key: string) => {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(`${key}=`))
    ?.split('=')[1];
};

export const deleteSessionCookie = (key: string) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=strict`;
};
