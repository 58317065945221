import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
  ToggleSwitch,
} from 'me-component-library';
import { useUser } from '../../../auth/hooks/useUser';
import { LoadingOverlay } from '../shared';
import { useTranslation } from '../../../_shared/hooks';
import { useEffect, useState } from 'react';
import { useUpdateOTP } from '../../hooks/useUpdateOTP';
import { get2FAImage } from '../../api';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { HTTPError } from 'ky';

export function UserSettings() {
  const t = useTranslation();
  const { data: userData, isLoading: isUserDataLoading, isError } = useUser();
  const { mutate, isLoading: isMutationLoading } = useUpdateOTP();
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [imageData, setImageData] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const isLoading = isUserDataLoading || isMutationLoading || isImageLoading;

  const formSchema = z.object({
    code: z.string().regex(/^\d{6}$/, t('auth.twoFactor.form.validationError')),
  });

  const showInputForm = imageData || (userData?.otpRequired && !otpEnabled);

  const toggleErrorToast = () => {
    toast({
      title: t('auth.twoFactor.toasts.error.title'),
      description: t('auth.twoFactor.toasts.error.message'),
      variant: 'error',
      duration: 5000,
    });
  };

  const toggleSuccesToast = (enabled: boolean) => {
    toast({
      title: t(
        enabled
          ? 'dashboard.userSettings.otp.toasts.success.enabled.title'
          : 'dashboard.userSettings.otp.toasts.success.disabled.title',
      ),
      description: t(
        enabled
          ? 'dashboard.userSettings.otp.toasts.success.enabled.description'
          : 'dashboard.userSettings.otp.toasts.success.disabled.description',
      ),
      variant: 'success',
      duration: 5000,
    });
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(
      {
        operation: otpEnabled ? 'enable' : 'disable',
        otpToken: values.code,
      },
      {
        onSuccess: () => {
          toggleSuccesToast(otpEnabled);
          setImageData('');
          form.reset();
        },
        onError: error => {
          if (error instanceof HTTPError) {
            if (error.response.status === 401) {
              form.setError('code', {
                message: t('auth.twoFactor.form.codeInvalid'),
              });
              return;
            }
          }
          toggleErrorToast();
        },
      },
    );
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
    },
  });

  useEffect(() => {
    setOtpEnabled(userData?.otpRequired ?? false);
  }, [userData]);

  const handleCheckBoxChange = async () => {
    // clear image data when toggling 2FA
    setImageData('');

    setOtpEnabled(!otpEnabled);

    if (!otpEnabled && !userData?.otpRequired) {
      try {
        setIsImageLoading(true);

        const data = await get2FAImage();
        if (data?.image) {
          setImageData(data.image);
        }
      } catch (error) {
        console.error('Error getting 2FA image', error);
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  if (isError) {
    return <div>{t('dashboard.userSettings.error')}</div>;
  }

  return (
    <div className="w-full bg-white px-8 py-12 shadow-lg relative">
      {isLoading && <LoadingOverlay />}
      <div className="flex justify-between items-center">
        <div className="text-xl">{t('dashboard.userSettings.otp.title')}</div>
        <div className="h-4">
          <ToggleSwitch
            enabled={otpEnabled}
            onChange={handleCheckBoxChange}
            inactiveLabel={t(
              'dashboard.userSettings.otp.toggleSwitch.inactive',
            )}
            activeLabel={t('dashboard.userSettings.otp.toggleSwitch.active')}
          />
        </div>
      </div>
      <div className="w-5/6 mt-2 text-sm">
        {t('dashboard.userSettings.otp.description')}
      </div>
      {showInputForm && (
        <div className="mt-4">
          {t(
            otpEnabled
              ? 'dashboard.userSettings.otp.form.enable.description'
              : 'dashboard.userSettings.otp.form.disable.description',
          )}
          <div className="mt-4 flex items-center justify-around">
            {imageData && (
              <div className="min-w-72">
                <img src={imageData} alt="QR code" />
              </div>
            )}
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8 min-w-72"
              >
                <FormField
                  control={form.control}
                  name="code"
                  key="code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('auth.twoFactor.form.label')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('auth.twoFactor.form.placeholder')}
                          data-testid="code-input"
                          type="text"
                          autoComplete="one-time-code"
                          autoFocus
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  className="w-full"
                  isLoading={isLoading}
                  disabled={!form.formState.isValid || isLoading}
                  data-testid="submit-button"
                >
                  {t('auth.setPassword.submitButton')}
                </Button>
              </form>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
}
