import { useToast } from 'me-component-library';
import { useTranslation } from '../../../../../_shared/hooks';
import { useUpdateQuota } from '../../../../hooks';
import { EmailDetail } from '../../../../types';
import { EmailEditTabStorageQuotaForm } from './EmailEditTabStorageQuotaForm';
import { convertQuotaToBytes } from '../../../../util';
import { QuotaExceededError } from '../../../../api';

interface EmailEditTabStorageProps {
  data: EmailDetail;
}

const convertBytesToGB = (bytes: number) => {
  return `${(bytes / (1000 * 1000 * 1000)).toFixed(2)} GB`;
};

export const EmailEditTabStorage: React.FC<EmailEditTabStorageProps> = ({
  data,
}) => {
  const t = useTranslation();
  const quotaLimit = data.mailbox.storageInBytes.limit ?? 0;
  const quotaLimitInGB = convertBytesToGB(quotaLimit);
  const quotaCurrent = data.mailbox.storageInBytes.current.value ?? 0;
  const quotaCurrentInGB = convertBytesToGB(quotaCurrent);
  const quotaUsagePercentage = (quotaCurrent / quotaLimit) * 100;
  const { mutate, isLoading } = useUpdateQuota();
  const { toast } = useToast();

  const toggleGenericErrorToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.shared.toasts.error.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.error.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const toggleQuotaExceededToast = () => {
    toast({
      title: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.quotaExceeded.title',
      ),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.quotaExceeded.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const toggleSuccesToast = () => {
    toast({
      title: t(
        'dashboard.emailEditTabs.tabs.storage.sections.quota.form.toasts.success.title',
      ),
      description: t(
        'dashboard.emailEditTabs.tabs.storage.sections.quota.form.toasts.success.message',
      ),
      variant: 'success',
      duration: 3000,
    });
  };

  const handleSubmit = async (quota: string) => {
    const quotaInBytes = convertQuotaToBytes(quota);

    if (quotaInBytes > 0) {
      mutate(
        { emailId: data.id, quotaInBytes },
        {
          onSuccess: () => {
            toggleSuccesToast();
          },
          onError: err => {
            if (err instanceof QuotaExceededError) {
              toggleQuotaExceededToast();
            } else {
              toggleGenericErrorToast();
            }
          },
        },
      );
    }
  };

  return (
    <>
      <div className="text-xl">
        {t('dashboard.emailEditTabs.tabs.storage.sections.currentUsage.title')}
      </div>
      <p className="mt-2 text-sm w-5/6">
        {t(
          'dashboard.emailEditTabs.tabs.storage.sections.currentUsage.description',
        )}
      </p>
      <div className="mt-5">
        {t('dashboard.emailEditTabs.tabs.storage.sections.currentUsage.value', {
          usage: quotaCurrentInGB,
          quota: quotaLimitInGB,
        })}
      </div>
      <div className="w-full h-4 bg-gray-200 mt-2">
        <div
          className="h-full bg-blue-500"
          style={{ width: `${quotaUsagePercentage}%` }}
        ></div>
      </div>
      <hr className="my-9" />
      <div className="text-xl">
        {' '}
        {t('dashboard.emailEditTabs.tabs.storage.sections.quota.title')}
      </div>
      <p className="mt-2 text-sm w-5/6">
        {t('dashboard.emailEditTabs.tabs.storage.sections.quota.description')}
      </p>
      <div className="mt-5">
        <EmailEditTabStorageQuotaForm
          quotaLimitInGB={quotaLimitInGB}
          raiseSubmit={handleSubmit}
          isLoading={isLoading}
          showSubmitButton
        />
      </div>
      <hr className="my-9" />
      <div className="text-sm">
        {t('dashboard.emailEditTabs.tabs.storage.lastUpdated')}{' '}
        {data.mailbox.storageInBytes.current.updatedAt
          ? new Date(
              data.mailbox.storageInBytes.current.updatedAt,
            ).toLocaleString()
          : t('dashboard.emailEditTabs.tabs.storage.never')}
      </div>
    </>
  );
};
