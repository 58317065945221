import { HTTPError } from 'ky';
import { api } from './api';
import { QuotaExceededError } from './customErrors';

export type CreateRegularEmailValues = {
  kind: string;
  address: string;
  isCatchAll: boolean;
  mailbox: {
    enableSpamProtection: boolean;
    password: string;
    quotaInBytes: number;
  };
};
export type CreateForwardingEmailValues = {
  kind: string;
  address: string;
  forwardAddresses: string[];
};

export async function createEmail({
  ...values
}: CreateRegularEmailValues | CreateForwardingEmailValues) {
  try {
    const response = await api.post(`emails`, {
      json: {
        ...values,
      },
    });

    return response.json();
  } catch (error) {
    if (error instanceof HTTPError) {
      const errorBody = await error.response.json();
      if (
        errorBody.message ===
        'Quota for new email exceeds maximum project quota'
      ) {
        throw new QuotaExceededError();
      }
    }
    throw error; // rethrow error if it's not a quota exceeded error
  }
}
