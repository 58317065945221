import { EnterTwoFactorForm } from '../../components/EnterTwoFactorForm';
import { BasePage } from '../BasePage';

export const TwoFactor: React.FC = () => {
  return (
    <BasePage>
      <EnterTwoFactorForm />
    </BasePage>
  );
};
