import { useMutation } from 'react-query';
import { getUserToken } from '../../api';
import { LoginCredentials } from '../../types';

export function useGetToken() {
  return useMutation(
    (variables: LoginCredentials) => getUserToken(variables),
    {},
  );
}
