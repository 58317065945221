export enum PATHS {
  DASHBOARD = 'dashboard',
  EMAILS = 'emails',
  EMAIL_ID = ':emailId',
  SETTINGS = 'settings',
  LOGIN = 'login',
  SET_PASSWORD = 'set-password',
  SET_PASSWORD_TOKEN = ':token',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  TWO_FACTOR = 'two-factor',
}
