import { api } from './api';

export type DisableUserOtpParams = {
  emailId: string;
};

export async function disableUserOtp({
  emailId,
}: DisableUserOtpParams): Promise<boolean> {
  const response = await api.patch(`emails/${emailId}/2fa/disable`, {
    json: {},
  });

  return response.ok;
}
