import { useToast } from 'me-component-library';
import { useUpdateForwardAddresses } from '../../../../hooks/useUpdateForwardAddresses';
import { EmailDetail } from '../../../../types';
import './EmailEditTabForwarding.scss';
import { EmailEditTabForwardingAddressLine } from './EmailEditTabForwardingAddressLine';
import { EmailEditTabForwardingForm } from './EmailEditTabForwardingForm';
import { useTranslation } from '../../../../../_shared/hooks';
interface EmailEditTabForwardingProps {
  data: EmailDetail;
}

export const EmailEditTabForwarding: React.FC<EmailEditTabForwardingProps> = ({
  data,
}) => {
  const { mutate, isLoading } = useUpdateForwardAddresses();
  const { toast } = useToast();
  const t = useTranslation();

  const toggleErrorToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.shared.toasts.error.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.error.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const toggleSuccessToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.forwarding.toasts.success.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.forwarding.toasts.success.message',
      ),
      variant: 'success',
      duration: 3000,
    });
  };

  const deleteEmail = (address: string) => {
    mutate(
      {
        emailId: data.id,
        forwardAddresses: data.forwardAddresses.filter(
          forwardAddress => forwardAddress !== address,
        ),
      },
      {
        onSuccess: () => toggleSuccessToast(),
        onError: () => toggleErrorToast(),
      },
    );
  };

  const addEmail = (address: string) => {
    mutate(
      {
        emailId: data.id,
        forwardAddresses: [...data.forwardAddresses, address],
      },
      {
        onSuccess: () => toggleSuccessToast(),
        onError: () => toggleErrorToast(),
      },
    );
  };

  return (
    <>
      <div className="text-xl">
        {t('dashboard.emailEditTabs.tabs.forwarding.title')}
      </div>
      <div className="w-5/6 text-sm">
        {t('dashboard.emailEditTabs.tabs.forwarding.description')}
      </div>
      <hr className="my-9" />
      <div className="text-xl mb-2">
        {t('dashboard.emailEditTabs.tabs.forwarding.form.title')}
      </div>
      <EmailEditTabForwardingForm
        addEmail={addEmail}
        isLoading={isLoading}
        existingEmails={[data.address, ...data.forwardAddresses]}
      />
      <hr className="my-9" />
      <div className="text-xl mb-2">
        {t('dashboard.emailEditTabs.tabs.forwarding.table.title')}
      </div>
      {data.forwardAddresses.map(address => (
        <EmailEditTabForwardingAddressLine
          key={address}
          address={address}
          isLoading={isLoading}
          deleteEmail={deleteEmail}
        />
      ))}
    </>
  );
};
