import { useMutation, useQueryClient } from 'react-query';
import { disableUserOtp, DisableUserOtpParams } from '../../api';

export const useDisableUserOtp = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: DisableUserOtpParams) => disableUserOtp(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`emails`]);
      },
    },
  );
};
