import { TwoFactorLoginResponse } from '@auth/types';
import { api } from '.';

export type RequestTwoFactorLoginValues = {
  otpAuthToken: string;
  code: string;
};

export const requestTwoFactorLogin = async ({
  otpAuthToken,
  code,
}: RequestTwoFactorLoginValues): Promise<TwoFactorLoginResponse> => {
  const response = await api.post('public/otp', {
    json: { otpAuthToken, otpToken: code },
  });
  if (!response.ok) {
    throw new Error('Failed to request two-factor login');
  }

  return response.json();
};
