import navbar from './navbar.json';
import emailDetails from './emailDetails.json';
import emailAddDialogs from './emailAddDialogs.json';
import emailTable from './emailTable.json';
import emailEditTabs from './emailEditTabs.json';
import sharedComponents from './sharedComponents.json';
import userSettings from './userSettings.json';

export const dashboard = {
  navbar,
  emailDetails,
  emailAddDialogs,
  emailTable,
  emailEditTabs,
  sharedComponents,
  userSettings,
};
