import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from 'me-component-library';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useTranslation } from '../../../../../_shared/hooks';

interface EmailEditTabForwardingFormProps {
  addEmail: (address: string) => void;
  isLoading: boolean;
  existingEmails: string[];
}

export const EmailEditTabForwardingForm: React.FC<
  EmailEditTabForwardingFormProps
> = ({ addEmail, isLoading, existingEmails }) => {
  const t = useTranslation();
  const formSchema = z.object({
    email: z
      .string()
      .email({
        message: t(
          'dashboard.emailEditTabs.tabs.forwarding.form.errors.invalidEmail',
        ),
      })
      .refine(
        value => {
          if (!existingEmails?.some(e => e === value)) {
            return true;
          }
          return false;
        },
        {
          message: t(
            'dashboard.emailEditTabs.tabs.forwarding.form.errors.alreadyExists',
          ),
        },
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    addEmail(values.email);
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Label className="select-none">
          {t('dashboard.emailEditTabs.tabs.forwarding.form.inputLabel')}
        </Label>
        <div className="flex gap-4">
          <FormField
            control={form.control}
            name="email"
            key="email"
            render={({ field }) => (
              <FormItem className="flex grow flex-col">
                <FormControl>
                  <Input
                    placeholder={t(
                      'dashboard.emailEditTabs.tabs.forwarding.form.inputPlaceHolder',
                    )}
                    data-testid="email-input"
                    className="w-full"
                    type="text"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="select-none"
            isLoading={isLoading}
            disabled={!form.formState.isValid || isLoading}
            data-testid="submit-button"
          >
            {t('dashboard.emailEditTabs.tabs.forwarding.form.button')}
          </Button>
        </div>
      </form>
    </Form>
  );
};
