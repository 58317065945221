import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  useToast,
} from 'me-component-library';
import { useState } from 'react';
import { useTranslation } from '../../../../_shared/hooks';
import { useDisableUserOtp } from '../../../hooks/useDisableUserOtp';

export interface Reset2FADialogProps {
  emailId: string;
  emailAddress: string;
  title: string;
}

export const Reset2FADialog = ({
  emailId,
  emailAddress,
  title,
}: Reset2FADialogProps) => {
  const t = useTranslation();
  const { mutate, isLoading, isError } = useDisableUserOtp();
  const { toast } = useToast();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleUserDelete = () => {
    mutate(
      {
        emailId,
      },
      {
        onSuccess: () => {
          closeDeleteModal();
          toast({
            title: t(
              'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.toast.success.title',
            ),
            description: t(
              'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.toast.success.description',
              {
                email: emailAddress,
              },
            ),
          });
        },
      },
    );
  };

  return (
    <Dialog open={isDeleteModalOpen}>
      <DialogTrigger onClick={openDeleteModal} asChild>
        <div className="mt-3 text-sm text-yellow-500 font-bold cursor-pointer">
          {title}
        </div>
      </DialogTrigger>
      <DialogContent onCloseClick={closeDeleteModal}>
        <DialogHeader>
          <DialogTitle>
            {t(
              'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.title',
            )}
          </DialogTitle>
          <DialogDescription>
            <span>
              {t(
                'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.subtitle.part1',
              )}
              <span className="font-bold">
                {t(
                  'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.subtitle.part2',
                  {
                    email: emailAddress,
                  },
                )}
              </span>
              {t(
                'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.subtitle.part3',
              )}
            </span>
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-between">
          <Button
            variant="destructive"
            data-testid="confirm-delete-user-button"
            isLoading={isLoading}
            onClick={() => handleUserDelete()}
          >
            {t(
              'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.button',
            )}
          </Button>
          <Button onClick={closeDeleteModal}>
            {t(
              'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.cancel',
            )}
          </Button>
        </div>
        {isError && (
          <span className="text-red-500">
            {t(
              'dashboard.emailEditTabs.tabs.general.common.options.reset2fa.modal.error',
            )}
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
};
