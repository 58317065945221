import { useGetCustomDomainData } from '../../../_shared/hooks/useGetCustomDomainData/useGetCustomDomainData';
import { CustomDomainData } from '../../../_shared/types';

export const CompanyLogo: React.FC = () => {
  const { data } = useGetCustomDomainData();
  const renderLogoSrc = (domaiData?: CustomDomainData) => {
    const defaultLogo = '/default-logo.png';

    if (domaiData === null || domaiData === undefined) {
      return defaultLogo;
    } else if (domaiData.imgLogin.length === 0) {
      return defaultLogo;
    }

    return domaiData?.logo;
  };
  return (
    <div className="h-24 w-24 mb-3">
      <img
        className="w-full h-full object-contain rounded-2xl"
        src={renderLogoSrc(data)}
      />
    </div>
  );
};
