import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Button,
  Card,
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useToast,
  Icon,
} from 'me-component-library';
import { useRequestTwoFactorLogin } from '../../hooks';
import { useTranslation } from '../../../_shared/hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../_shared/types';
import { useEffect, useState } from 'react';
import { HTTPError } from 'ky';
import { deleteSessionCookie, readSessionCookie } from '../../../_shared/utils';

export function EnterTwoFactorForm() {
  const { mutate, isLoading } = useRequestTwoFactorLogin();
  const { toast } = useToast();
  const navigate = useNavigate();
  const t = useTranslation();
  const [otpAuthToken, setOtpAuthToken] = useState<string | null>(null);

  const formSchema = z.object({
    code: z.string().regex(/^\d{6}$/, t('auth.twoFactor.form.validationError')),
  });

  const toggleErrorToast = () => {
    toast({
      title: t('auth.twoFactor.toasts.error.title'),
      description: t('auth.twoFactor.toasts.error.message'),
      variant: 'error',
      duration: 5000,
    });
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
    },
  });

  useEffect(() => {
    const token = readSessionCookie('otpAuthToken');

    if (!token) {
      navigate(`/${PATHS.LOGIN}`);
      return;
    }

    setOtpAuthToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutate(
      {
        code: values.code,
        otpAuthToken: otpAuthToken ?? '',
      },
      {
        onSuccess: data => {
          localStorage.setItem('accessToken', data.accessToken);
          deleteSessionCookie('otpAuthToken');

          navigate(`/${PATHS.DASHBOARD}`);
        },
        onError: error => {
          if (error instanceof HTTPError) {
            if (error.response.status === 401) {
              form.setError('code', {
                message: t('auth.twoFactor.form.codeInvalid'),
              });
            } else {
              toggleErrorToast();
            }
          }
        },
      },
    );
  }

  return (
    <div className="flex justify-center items-center">
      <Card className="w-96 shadow-xl py-12 bg-white px-8 rounded-md">
        <h1 className="text-2xl mb-1">
          <Icon
            name="chevronLeft"
            className="cursor-pointer my-1"
            size="40"
            onClick={() => navigate('/' + PATHS.LOGIN + '/')}
          />
          {t('auth.twoFactor.title')}
        </h1>
        <p className="text-s mb-8">{t('auth.twoFactor.subtitle')}</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="code"
              key="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('auth.twoFactor.form.label')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('auth.twoFactor.form.placeholder')}
                      data-testid="code-input"
                      type="text"
                      autoComplete="one-time-code"
                      autoFocus
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              isLoading={isLoading}
              disabled={!form.formState.isValid || isLoading}
              data-testid="submit-button"
            >
              {t('auth.setPassword.submitButton')}
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
}
