import login from './login.json';
import setPassword from './setPassword.json';
import forgotPassword from './forgotPassword.json';
import twoFactor from './twoFactor.json';

export const auth = {
  login,
  setPassword,
  forgotPassword,
  twoFactor,
};
