import { useLocation, useNavigate } from 'react-router-dom';
import { NavTab } from './NavTab';
import { TranslationKey } from '../../../_shared/types/i18n';
import { useTranslation } from '../../../_shared/hooks';

export type TabStackProps = {
  categoryTitle: TranslationKey;
  tabList: {
    tabTitle: TranslationKey;
    icon: React.ReactNode;
    path: string;
  }[];
};

export const TabStack: React.FC<TabStackProps> = ({
  categoryTitle,
  tabList,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const t = useTranslation();

  const isActive = (selectedPath: string) => {
    return pathname === `/${selectedPath}`;
  };

  const renderTabs = tabList.map(({ path, ...rest }) => {
    function onClick() {
      navigate(`/${path}`);
    }

    return (
      <NavTab
        isActive={isActive(path)}
        onClick={onClick}
        key={path}
        {...rest}
      />
    );
  });

  return (
    <div className="flex w-full flex-col gap-2">
      <span className="text-xs text-gray-600">{t(categoryTitle)}</span>
      {renderTabs}
    </div>
  );
};
