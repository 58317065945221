import { Avatar, AvatarFallback } from 'me-component-library';
import { useUser } from '../../../auth/hooks';

export const UserAvatar: React.FC = () => {
  const { data } = useUser();

  const firstTwoLetterOfEmail =
    !data?.firstName || !data?.lastName
      ? 'WL'
      : //merge first letter of firstName and lastName
        `${data?.firstName[0]}${data?.lastName[0]}`;

  if (
    data === null ||
    data === undefined ||
    !data.roles ||
    data.roles.length === 0
  )
    return '...';

  return (
    <div className="flex items-center justify-center w-full py-2 px-4 gap-4 ">
      <Avatar>
        <AvatarFallback>{firstTwoLetterOfEmail}</AvatarFallback>
      </Avatar>
      <div className="flex w-full text-sm flex-col">
        <span className="text-slate-800 truncate w-full ">{data?.email}</span>
        <span className="text-sm text-slate-500">
          {data?.roles[0] === 'EMAIL_ADMIN' ? 'Admin' : 'User'}
        </span>
      </div>
    </div>
  );
};
