import { HTTPError } from 'ky';
import { api } from './api';
import { QuotaExceededError } from './customErrors';

export type UpdateStorageValues = {
  emailId: string;
  quotaInBytes: number;
};

export async function updateStorageQuota({
  emailId,
  quotaInBytes,
}: UpdateStorageValues) {
  try {
    const response = await api.patch(`emails/${emailId}/quota`, {
      json: {
        quotaInBytes,
      },
    });
    return response.json();
  } catch (error) {
    if (error instanceof HTTPError) {
      const errorBody = await error.response.json();
      if (
        errorBody.message ===
        'Quota for new email exceeds maximum project quota'
      ) {
        throw new QuotaExceededError();
      }
    }
    throw error; // rethrow error if it's not a quota exceeded error
  }
}
