import { api } from './api';

export type UpdateOTPResponse = {
  image?: string;
};

export const get2FAImage = async (): Promise<UpdateOTPResponse> => {
  const response = await api.get(`me/2fa/secret`);

  if (response.status === 200) {
    return response.json();
  }
  return { image: undefined };
};
