import {
  DataTable,
  ColumnDef,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Icon,
} from 'me-component-library';
import { Email } from '../../types';
import { useTranslation } from '../../../_shared/hooks';
import { EmailSelectButton } from './EmailSelectButton';
import { useGetEmails } from '../../hooks';
import { EmailListSkeleton } from '../EmailListSkeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Forward, Mail, MailboxIcon, UserCircle } from 'lucide-react';
import { EmailTableSearchBar } from './EmailTableSearchBar';

export const EmailTable = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetEmails();
  const [searchParams] = useSearchParams();

  const searchParam = searchParams.get('search')?.toLowerCase();
  const filteredData =
    searchParams &&
    data?.filter(
      email =>
        email.email.toLowerCase().includes(searchParam ?? '') ||
        email.user?.firstName.toLowerCase().includes(searchParam ?? '') ||
        email.user?.lastName.toLowerCase().includes(searchParam ?? '') ||
        email.user?.company.toLowerCase().includes(searchParam ?? '') ||
        email.forwardAddresses.some(address =>
          address.toLowerCase().includes(searchParam ?? ''),
        ),
    );

  const handleClick = (id: string, hasMailbox: boolean) => {
    navigate(`/dashboard/emails/${id}`, {
      state: { hasMailbox: hasMailbox },
    });
  };

  const columns: ColumnDef<Email>[] = [
    {
      accessorKey: 'type',
      header: t('dashboard.emailTable.headers.type'),
      cell: ({ row }) => (
        <div
          className="cursor-pointer flex justify-start"
          onClick={() => handleClick(row.original.id, row.original.hasMailbox)}
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                {row.original.hasMailbox ? <Mail /> : <Forward />}
              </TooltipTrigger>
              <TooltipContent>
                {t(
                  row.original.hasMailbox
                    ? 'dashboard.emailTable.tooltips.isMailbox'
                    : 'dashboard.emailTable.tooltips.isForward',
                )}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
    {
      accessorKey: 'email',
      header: t('dashboard.emailTable.headers.email'),
      cell: ({ row }) => (
        <div
          className="cursor-pointer"
          onClick={() => handleClick(row.original.id, row.original.hasMailbox)}
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="max-w-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                {row.original.email ?? ''}
              </TooltipTrigger>
              <TooltipContent>{row.original.email ?? ''}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: t('dashboard.emailTable.headers.name'),
      cell: ({ row }) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() =>
              handleClick(row.original.id, row.original.hasMailbox)
            }
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="max-w-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {row.original.user?.firstName ?? ''}{' '}
                  {row.original.user?.lastName ?? ''}
                </TooltipTrigger>
                <TooltipContent>
                  {row.original.user?.firstName ?? ''}{' '}
                  {row.original.user?.lastName ?? ''}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
    },
    {
      accessorKey: 'company',
      header: t('dashboard.emailTable.headers.company'),
      cell: ({ row }) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() =>
              handleClick(row.original.id, row.original.hasMailbox)
            }
          >
            {row.original.user?.company ?? ''}
          </div>
        );
      },
    },
    {
      id: 'status',
      header: t('dashboard.emailTable.headers.status'),
      cell: ({ row }) => (
        <div className="select-none flex gap-1">
          <TooltipProvider>
            {row.original.isCatchAll && (
              <Tooltip>
                <TooltipTrigger>
                  <MailboxIcon />
                </TooltipTrigger>
                <TooltipContent>
                  {t('dashboard.emailTable.tooltips.isCatchAll')}
                </TooltipContent>
              </Tooltip>
            )}
            {row.original.user !== undefined && (
              <Tooltip>
                <TooltipTrigger>
                  <UserCircle
                    color={
                      row.original.user?.roles?.includes('EMAIL_ADMIN')
                        ? 'red'
                        : 'black'
                    }
                  />
                </TooltipTrigger>
                <TooltipContent>
                  {t(
                    row.original.user?.roles?.join(', ') === 'EMAIL_ADMIN'
                      ? 'dashboard.emailTable.roles.admin'
                      : 'dashboard.emailTable.roles.user',
                  )}
                </TooltipContent>
              </Tooltip>
            )}
            {row.original.user?.otpRequired && (
              <Tooltip>
                <TooltipTrigger>
                  <Icon name="keySquare" />
                </TooltipTrigger>
                <TooltipContent>
                  {t('dashboard.emailTable.tooltips.2fa')}
                </TooltipContent>
              </Tooltip>
            )}
          </TooltipProvider>
        </div>
      ),
    },
    {
      id: 'actions',
      header: t('dashboard.emailTable.headers.actions'),
      cell: ({ row }) => (
        <EmailSelectButton
          onClick={() => handleClick(row.original.id, row.original.hasMailbox)}
        />
      ),
    },
  ];

  if (!isLoading && !data) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <h2 className="text-2xl text-gray-400 mb-2">
          {t('dashboard.emailTable.emptyState.title')}
        </h2>
        <p className="text-gray-400">
          {t('dashboard.emailTable.emptyState.description')}
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-end">
      <div className="flex md:justify-end mb-4">
        <EmailTableSearchBar />
      </div>
      {isLoading && <EmailListSkeleton />}
      {data && <DataTable columns={columns} data={filteredData ?? []} />}
    </div>
  );
};
